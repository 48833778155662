import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
// import chromecast from 'vue-chromecast-plugin'
import Vuelidate from 'vuelidate'
import Vuetify from 'vuetify'
import SocialSharing from 'vue-social-sharing'
import Meta from 'vue-meta'
import '@fortawesome/fontawesome-free/css/all.css'
import 'vuetify/dist/vuetify.min.css'
import './filters'
import VueAnalytics from 'vue-analytics'
// Vue.use(chromecast, {appId: 'E1463BDD'})
import sanitizeHTML from 'sanitize-html'

Vue.use(Meta)

Vue.use(VueAnalytics, {
  id: 'UA-141522241-1'
})

Vue.use(Vuelidate)
Vue.use(SocialSharing)
Vue.use(Vuetify, {
  iconfont: 'fa',
  theme: false,
  options: {
    themeCache: {
      get: () => ''
    }
	}
})

Vue.prototype.$sanitize = sanitizeHTML
Vue.config.productionTip = false
Vue.config.ignoredElements = ['cast-media-player', 'google-cast-button']

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
