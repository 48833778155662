<template>
  <!-- <div
    v-if="media && typeof media !== 'string' && media.length > 0"
    class="inventory-model-media">
    <div v-for="(item, key) in media"
      :key="key"
      :class="[
        active === key ? 'is-active': ''
      ]"
      :data-slides="key"
      class="inventory-model-media__inner">
      <img class="inventory-model-media__image" :src="'/uploads' + item.path + item.file" />
    </div>
  </div> -->
  <v-carousel
    v-if="media && typeof media !== 'string' && media.length > 0"
    interval="30000"
    class="inventory-model-media">
    <v-carousel-item
      v-for="(item,i) in media"
      :key="i"
      :src="'/uploads' + item.path + item.file"
    ></v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: 'inventory-model',
  props: {
    media: {
      type: Array,
      default: function () {
        return []
      }
    },
    config: {},
    start: {}
  },

  data () {
    return {
      theInterval: false,
      active: 0,
      index: 0,
      timing: 10000
    }
  },

  methods: {
    runSlideshow () {
      let self = this
      self.theInterval = setInterval(() => {
        self.index++
        if (self.index === self.media.length) {
          self.index = 0
        }
        self.active = self.index
      }, self.timing)
    }
  },

  created () {
    let self = this
    this.active = this.start || 0
    this.index = this.start || 0
    let ran = Math.floor(Math.random() * 6) * 200
    setTimeout(() => {
      self.runSlideshow()
    }, ran)
  },

  destroyed () {
    clearInterval(this.theInterval)
    this.index = 0
    this.active = 0
  }
}
</script>
