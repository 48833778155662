<template>
  <footer class="footer">
    <div class="footer-container">
      <div class="footer-column">
        <div
          v-if="missionStatement"
          class="footer-mission-statement">{{missionStatement}}</div>

        <ul class="footer-social__list" v-if="socialNav">
          <li
            v-for="(item, index) in socialNav"
            :key="index"
            class="footer-social__item">
            <a v-if="item" :href="item" class="footer-social__link" target="_blank">
              <Icon :icon-name='index' class='icon icon--black' />
            </a>
          </li>
        </ul>
      </div>
      <div class="footer-column" v-if="menu && menu.length > 0">
        <h3 class="footer__title">Useful Links</h3>
        <ul class="footer-nav">
          <li
            v-for="(item, index) in menu"
            :key="index"
            class="footer-nav__item">
            <router-link :to="item.url" class="footer-nav__link">
              {{item.title}}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer-subline">
      <div class="footer-subline-inner">
        &copy; {{year}} Copyright The Ashtree Collection, all rights reserved.
      </div>
    </div>
    <cookie-consent :href="privacy_url" v-if="privacy_url" />
  </footer>
</template>

<script>
import Icon from '../components/icons/Icon.vue'
import CookieConsent from 'vue-cookieconsent-component'

export default {
  name: 'site-footer',

  components: { Icon, CookieConsent },

  props: {
    menu: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  data () {
    return {
      socialNav: {},
      missionStatement: false,
      privacy_url: false,
      year: false
    }
  },

  created () {
    let d = new Date()
    this.year = d.getFullYear()
  },

  mounted () {
    this.$store.watch(() => this.$store.getters.genSettings, res => {
      if (res) {
        if (res.facebookUrl) {
          this.socialNav.facebook = res.facebookUrl
        }
        if (res.twitterUrl) {
          this.socialNav.twitter = res.twitterUrl
        }
        if (res.instagramUrl) {
          this.socialNav.instagram = res.instagramUrl
        }
        if (res.missionStatement) {
          this.missionStatement = res.missionStatement
        }
        if (res.privacy_url) {
          this.privacy_url = res.privacy_url
        }
      }
    }, {
      immediate: true
    })

    this.$forceUpdate()
  }
}
</script>
