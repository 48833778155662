<template>
  <div v-if="categories && categories.length > 0" class="partners">
    <div
      class="partners-list"
      v-for="(category, key) in categories"
      :key="'cat_' + key"
    >
      <h2 class="py-3" v-if="category.cat_name">{{category.cat_name}}</h2>
      <div
        class="partners-list__card"
        v-for="(item, keyb) in category.partners"
        :key="'partner_' + keyb"
        :class="[
          item.partner_logo ? 'has-logo' : 'no-logo',
          item.partner_info ? 'has-bio' : 'no-bio'
        ]">
        <div
          class="partners__logo"
          v-if="item.partner_logo">
          <div
            class="partners__logo-image"
            :style="{ 'background-image': 'url(/uploads' + item.partner_logo.path + item.partner_logo.file + ')' }">
          </div>
        </div>

        <div class="partners__content">
          <a :href="item.partner_url" target="_blank" class="partners__link">{{item.partner_name}}</a>
          <div
            class="partners__bio"
            v-if="item.partner_info">
            {{item.partner_info}}
          </div>

          <a :href="item.partner_url" target="_blank" class="partners__sublink">{{item.partner_url}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPostId } from '../../api/api.js'
export default {
  name: 'partners-list',

  props: {
    colour: {
      type: String
    },
    params: {}
  },

  data() {
    return {
      dataKey: 0,
      categories: [],
      category: false
    }
  },

  created () {
    let cat = this.params.panelOption ? this.params.panelOption : 0
    getPostId('partners/getPartners', cat).then(results => {
      if (results.data) {
        let list = results.data.list
        this.dataKey = new Date().getTime()

        list.forEach(partner => {
          if (typeof partner.partner_logo === 'string' && partner.partner_logo !== '') {
            partner.partner_logo = JSON.parse(partner.partner_logo)
          } else {
            partner.partner_logo = false
          }

          partner.partner_info = partner.partner_info === '' ? false : partner.partner_info

          if (partner.partner_url.substring(0, 4) !== 'http') {
            partner.partner_url = 'http://' + partner.partner_url
          }
        })

        if (results.data.cat) {
          this.category = results.data.cat
        }

        this.processPartners(results.data.cat, list)

        // this.partners = list
      }
    })
  },

  methods: {
    processPartners (cats, partners) {
      if (cats && partners) {
        const list = []
        const partnerList = []
        cats.forEach(cat => {
          list[cat.cat_id] = {
            cat_name: cat.cat_name,
            show: false,
            partners: []
          }
        })

        partners.forEach(partner => {
          list[partner.partner_cat].partners.push(partner)
          list[partner.partner_cat].show = true
        })

        list.forEach(item => {
          if (item && item.show) {
            partnerList.push(item)
          }
        })

        this.categories = partnerList
      }
    }
  }
}
</script>
