<template>
  <div
    v-if="media && typeof media !== 'string' && media.length > 0"
    class="inventory-section-media">
    <div v-for="(item, key) in media"
      :key="key"
      :class="[
        active === key ? 'is-active': ''
      ]"
      :data-slides="key"
      class="inventory-section-media__inner"
      @click="open(key)">
      <div
        class="inventory-section-media__image"
        v-if="item.type === 'image' && config !== 'above'"
        :style="{ 'background-image': 'url(/uploads' + item.path + '/thumbnail_' + item.file + ')' }"
      ></div>
      <div
        class="inventory-section-media__image"
        v-if="item.type === 'image' && config === 'above'"
        :style="{ 'background-image': 'url(/uploads' + item.path + item.file + ')' }"
      ></div>
    </div>
    <v-dialog v-model="dialog" class="pa-3 inventory-modal">
      <v-card>
        <v-card-title>
          <span class="headline">Images</span>
        </v-card-title>
        <inventoryModel :media="modelMedia" :start="activeIndex" />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn light @click="close">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import inventoryModel from './inventory-model'
export default {
  name: 'inventory-section-media',
  components: {inventoryModel},
  props: {
    media: {
      type: Array,
      default: function () {
        return []
      }
    },
    config: {}
  },

  data () {
    return {
      theInterval: false,
      active: 0,
      index: 0,
      timing: 10000,
      dialog: false,
      activeIndex: 0,
      modelMedia: []
    }
  },

  methods: {
    runSlideshow () {
      let self = this
      self.theInterval = setInterval(() => {
        self.index++
        if (self.index === self.media.length) {
          self.index = 0
        }
        self.active = self.index
      }, self.timing)
    },

    open (index) {
      this.activeIndex = index
      this.modelMedia = this.media
      this.$forceUpdate()

      setTimeout(() => {
        this.dialog = true
      })
    },

    close () {
      this.dialog = false
      this.activeIndex = -1
      this.modelMedia = []
    }
  },

  created () {
    let self = this
    let ran = Math.floor(Math.random() * 6) * 200
    setTimeout(() => {
      self.runSlideshow()
    }, ran)
  },

  destroyed () {
    clearInterval(this.theInterval)
  }
}
</script>
