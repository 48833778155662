<template>
  <div :key="dataKey" class="testimonial-list">
    <div v-for="(item, key) in list" :key="key" class="testimonial-list-item">
      <div class="testimonial-list-item-content">
        <p class="rte" v-html="item.content" />
        <p v-if="item.name" class="rte" v-text="item.name" />
        <p v-if="item.date" class="rte" v-text="dateFormat(item.date)" />
      </div>
    </div>
  </div>
</template>

<script>
import { getData } from '../../api/api.js'
import moment from 'moment'
export default {
  name: 'testimonial-list',
  props: {
    colour: {
      type: String
    },
    params: {}
  },

  data() {
    return {
      dataKey: 0,
      list: []
    }
  },
  created () {
    getData('testimonials/getTestimonials').then(results => {
      if (results && results.data) {
        this.list = results.data
        this.dataKey = new Date().getTime()
      }
    })
  },
  methods: {
    dateFormat (date) {
      if (date) {
        return moment(date).format('Do MMMM YYYY')
      }
    }
  }
}
</script>
