<template>
  <div class="news">

    <content-view-inner
      v-if="article.news_content"
      :content="article.news_content" />
  </div>
</template>

<script>
import { getPostId } from '../../api/api'
import { EventBus } from '../../helper/event-bus'
import contentViewInner from '../content-view/content-view-inner'
export default {
  name: 'news-article',

  components: {contentViewInner},

  data() {
    return {
      article: false,
      tags: []
    }
  },

  created () {
    let url = '/' + this.$route.params.var

    getPostId('news/getNewsArticleFE', url).then(result => {
      if (result && result.data) {
        let hero = []
        this.tags = result.data.tags
        let article = result.data.article
        EventBus.$emit('update-page-title', article.news_title)

        if (typeof article.news_content === 'string') {
          article.news_content = JSON.parse(article.news_content)
        }
        if (typeof article.news_hero === 'string') {
          article.news_hero = JSON.parse(article.news_hero)
        }
        if (typeof article.news_tags === 'string') {
          article.news_tags = JSON.parse(article.news_tags)
        }

        if (article.news_hero.length > 0) {
        article.news_hero.forEach(item => {
          hero.push({
            file: item.file,
            type: 'image',
            id: item.id
          })
        });

        if (hero.length > 0) {
          EventBus.$emit('updateHeroContent', { items: hero, blurOption: false })
        }

        EventBus.$emit('news-filters', {state: true})
      }

        this.article = article
      }
    })
  }
}
</script>
