<template>
  <div class="page-content" v-resize="onResize">
    <div
      v-if="hero && hero.length > 0"
      :class="[
        blurOption ? 'hero-wrapper-blurred' : 'hero-wrapper'
      ]"
    >
      <mediaContainer :media="hero" :hero="true" :navigation="true" :blurOption="blurOption" />
      <h1
        v-show="pageContent.hero_title"
        class="hero-title"
        :class="[
          pageSubtitle ? 'has-subtitle' : ''
        ]">{{pageTitle}}</h1>
      <h2
        v-if="pageSubtitle"
        v-show="pageContent.hero_title"
        class="hero-subtitle">{{pageSubtitle}}</h2>

      <div class="home-search" v-if="showSearch">
        <fieldset class="home-search__outer">
          <legend class="home-search__label">Search</legend>
        </fieldset>
      </div>

      <button class="btn btn--plain hero-cast" @click="castMedia" v-if="castVisable">
        <icon :iconName="castState" :class="[ castStatus ? 'icon--blue' : 'icon--white']" />
        <button is="google-cast-button" />
      </button>
    </div>

    <!-- <div class="inventory-search__filter" v-if="showInventoryFilters">
      <inventory-filters :filters="showInventoryFiltersData" />
    </div> -->

    <div class="inventory-details__subheader" v-if="showInventorySubHeader && showInventorySubHeaderData">
      <div class="inventory-details__subheader-inner">
        <ul v-if="showInventorySubHeaderData">
          <li v-for="(item, key) in showInventorySubHeaderData" :key="key">
            <div
              class="inventory-details__subheader-title"
              :class="[
                key === 'sold' ? 'is-single' : ''
              ]"><span>{{key}}</span></div>
            <div class="inventory-details__subheader-value" v-if="key !== 'sold'">{{item}}</div>
          </li>

          <li v-if="sharingOn" class="share">
            <social-sharing
              :url="shareUrl"
              :title="pageTitle"
              :description="metaTeaser"
              twitter-user="ashtreecoll"
              inline-template>
            <v-speed-dial
              v-model="fabShare"
              direction="left"
              :transition="fabTransition">
              <template v-slot:activator>
                <v-btn
                  class="ma-0"
                  v-model="fabShare"
                  depressed
                  flat
                  dark
                  color="text--white"
                  fab
                >
                  <i class="fas fa-share-alt"></i>
                </v-btn>
              </template>
              <network network="facebook">
                <v-btn
                  fab
                  small
                  class="btn--facebook">
                  <i class="fab fa-facebook-f"></i>
                </v-btn>
              </network>
              <network network="twitter">
                <v-btn
                  fab
                  small
                  class="btn--twitter">
                  <i class="fab fa-twitter"></i>
                </v-btn>
              </network>
              <network network="pinterest">
                <v-btn
                  fab
                  small
                  class="btn--pinterest">
                  <i class="fab fa-pinterest"></i>
                </v-btn>
              </network>
              <network network="whatsapp">
                <v-btn
                  fab
                  small
                  class="btn--whatsapp">
                  <i class="fab fa-whatsapp"></i>
                </v-btn>
              </network>
              <network network="email">
                <v-btn
                  fab
                  small
                  color="light-blue darken-2"
                >
                  <i class="fas fa-at"></i>
                </v-btn>
              </network>
              <network network="sms">
                <v-btn
                  fab
                  small
                  color="light-green"
                >
                  <i class="far fa-comment"></i>
                </v-btn>
              </network>
            </v-speed-dial>
            </social-sharing>
          </li>
        </ul>
      </div>
    </div>

    <div class="inventory-details__subheader" v-if="showNewsFilterSubHeader">
      <div class="inventory-details__subheader-inner">
        <div class="news-share">
          <social-sharing
              :url="shareUrl"
              :title="pageTitle"
              :description="metaTeaser"
              twitter-user="ashtreecoll"
              inline-template>
            <v-speed-dial
              v-model="fabShare"
              direction="left"
              :transition="fabTransition">
              <template v-slot:activator>
                <v-btn
                  class="ma-0"
                  v-model="fabShare"
                  depressed
                  flat
                  dark
                  color="text--white"
                  fab
                >
                  <i class="fas fa-share-alt"></i>
                </v-btn>
              </template>
              <network network="facebook">
                <v-btn
                  fab
                  small
                  class="btn--facebook">
                  <i class="fab fa-facebook-f"></i>
                </v-btn>
              </network>
              <network network="twitter">
                <v-btn
                  fab
                  small
                  class="btn--twitter">
                  <i class="fab fa-twitter"></i>
                </v-btn>
              </network>
              <network network="pinterest">
                <v-btn
                  fab
                  small
                  class="btn--pinterest">
                  <i class="fab fa-pinterest"></i>
                </v-btn>
              </network>
              <network network="whatsapp">
                <v-btn
                  fab
                  small
                  class="btn--whatsapp">
                  <i class="fab fa-whatsapp"></i>
                </v-btn>
              </network>
              <network network="email">
                <v-btn
                  fab
                  small
                  color="light-blue darken-2"
                >
                  <i class="fas fa-at"></i>
                </v-btn>
              </network>
              <network network="sms">
                <v-btn
                  fab
                  small
                  color="light-green"
                >
                  <i class="far fa-comment"></i>
                </v-btn>
              </network>
            </v-speed-dial>
            </social-sharing>
        </div>
      </div>
    </div>

    <content-view
      v-if="pageContent.content"
      :content="pageContent.content" />

  </div>
</template>

<script>
import {
  // getData,
  getPostId
} from '../api/api'
import contentView from '../components/content-view/content-view'
import mediaContainer from '../components/media-container/media-container'
import icon from '../components/icons/Icon'
// import inventoryFilters from '../components/filters/filters'
import { EventBus } from '../helper/event-bus'

import socialSharing from 'vue-social-sharing'
import meta from '../utils/meta.js'

export default {
  name: 'page-content',

  components: {
    mediaContainer,
    contentView,
    icon,
    // inventoryFilters,
    socialSharing
  },

  mixins: [meta],

  metaInfo () {
    return {
      titleTemplate: this.contentTitle,
      meta: this.meta
    }
  },

  data () {
    return {
      sharingOn: true,
      page: null,
      pageContent: false,
      contentTitle: false,
      meta: false,
      nohero: false,
      pageSubtitle: false,
      pageTitle: false,
      metaHero: false,
      metaTeaser: false,
      hero: [],
      blurOption: false,
      showSearch: false,
      showInventoryFilters: false,
      showInventoryFiltersData: {},
      showInventorySubHeader: false,
      showInventorySubHeaderData: {},

      showNewsFilterSubHeader: false,

      castState: 'cast',
      castStatus: false,
      castVisable: false,
      chrome: null,

      fabShare: false,
      shareState: 'share',
      fabDirection: 'left', // bottom
      fabTransition: 'scale-transition', //
      shareUrl: window.location.origin + window.location.pathname
    }
  },

  methods: {
    onResize () {
      this.fabDirection = window.innerWidth < 640 ? 'bottom' : 'left'
    },
    castMedia () {
      // const { cast } = window
      // // this.castStatus = ! this.castStatus
      // // this.castState = this.castStatus ? 'cast-connected' : 'cast'

      // // Get device list
      // let context = cast.framework.CastContext.getInstance()

      // context.requestSession()
    }
  },

  created () {
    const { chrome } = window
    if (chrome) {
      this.castVisable = false
    }

    this.chrome = chrome

    let url = this.$route.params.url ? '/' + this.$route.params.url : '/'

    getPostId('cms/getPageContent', url).then(results => {
      let response = {}
      response.title = results.data.title
      response.hero = JSON.parse(results.data.hero)
      response.content = JSON.parse(results.data.content)
      response.hero_title = results.data.hero_title === '1' ? true : false

      this.pageContent = response
      this.contentTitle = response.title === 'The Ashtree Collection' ? '' : '%s | ' + response.title
      // this.meta = this.setMeta(this.contentTitle, results.data.teaser, response.hero)
      this.pageTitle = response.title
      this.metaHero = response.hero
      this.metaTeaser = results.data.teaser
      if (this.hero.length < 1) {
        this.hero = this.pageContent.hero
      }

      if (this.hero && this.hero.length > 0) {
        this.nohero = false
        document.body.classList.remove('no-hero')
        document.body.classList.add('has-hero')
      } else {
        this.nohero = true
        document.body.classList.remove('has-hero')
        document.body.classList.add('no-hero')
      }

      this.$ga.page({
        page: window.location.pathname ? window.location.pathname : '/',
        title: this.pageTitle,
        location: window.location.href
      })
    })

    EventBus.$on('hero-search', (state) => {
      this.showSearch = state
    })

    EventBus.$on('inventory-filters', (response) => {
      this.showInventoryFilters = response.state
      this.showInventoryFiltersData = response.data
    })

    EventBus.$on('inventory-datails', (details) => {
      if (details && details.state) {
        this.showInventorySubHeader = details.state
        this.showInventorySubHeaderData = details.data
      }
    })

    EventBus.$on('news-filters', (details) => {
      if (details && details.state) {
        this.showNewsFilterSubHeader = details.state
        // this.showInventorySubHeaderData = details.data
      }
    })

    EventBus.$on('update-page-title', (title) => {
      if (title) {
        this.pageTitle = title
        this.contentTitle = '%s | ' + title
        this.meta = this.setMeta(this.contentTitle, this.metaTeaser, this.metaHero)
      }
    })

    EventBus.$on('update-page-subtitle', (title) => {
      if (title) {
        this.pageSubtitle = title
        this.contentTitle = this.contentTitle + ' ' + title
        this.meta = this.setMeta(this.contentTitle, this.metaTeaser, this.metaHero)
      }
    })

    EventBus.$on('updateHeroContent', (heroData) => {
      if (heroData && heroData.items && heroData.items.length > 0) {
        this.hero = heroData.items
        this.blurOption = heroData.blurOption
        document.body.classList.remove('no-hero')
        EventBus.$emit('hero', true)

        this.metaHero = this.hero
        this.meta = this.setMeta(this.contentTitle, this.metaTeaser, this.metaHero)
      }
    })
  },

  mounted () {
    this.onResize()
    let _self = this
    document.addEventListener('click', function (event) {
      if (event.stopPropagation) {
        event.stopPropagation()
      } else if (window.event) {
        window.event.cancelBubble = true
      }

      if (event.target.classList[0] === 'rte-link') {
        event.preventDefault()
        _self.$router.push({ path: event.target.pathname })
      }
    })
  },

  destroyed () {
    document.removeEventListener('click', function () {})
    this.page = null
    this.pageContent = false
  }
}
</script>
