<template>
  <v-app
    v-if="loaded && !maintenance"
    dark
    class="page-wrapper"
    :class="[
      isRoot ? 'is-root' : ''
    ]">
    <Header />
    <transition
      name="fade"
      mode="out-in">
      <router-view :key="$route.path" />
    </transition>
    <Footer :menu="footerMenu" />
  </v-app>
  <div
    v-else-if="!loaded"
    :class="[
      'app-loading page-wrapper',
      fadeout ? 'fadeout' : ''
    ]">
    <div class="loading-icon clipping-logo"></div>
  </div>
  <div class="site-maintenance" v-else-if="maintenance && loaded">
    <img src="./assets/ashtree-logo.png" width="400" height="118">
  </div>
</template>

<script>
import Header from './partials/header'
import Footer from './partials/footer'
import { getData } from './api/api'

export default {
  components: { Header, Footer },

  metaInfo: {
    // Children can override the title.
    title: 'The Ashtree Collection',
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    // titleTemplate: '%s ← My Site',
    // Define meta tags here.
    meta: [
      {
        'http-equiv': 'Content-Type',
        'content': 'text/html; charset=utf-8'
      },
      {
        'name': 'viewport',
        'content': 'width=device-width, initial-scale=1'
      },
      {
        vmid: 'description',
        name: 'description',
        'content': 'By Collectors for Collectors.'
      }
    ],
    links: [
      {
        rel: 'canonical',
        href: 'https://www.theashtreecollection.co.uk/'
      }
    ]
  },

  data () {
    return {
      isRoot: false,
      loaded: false,
      fadeout: false,
      footerMenu: [],
      progress: 0,
      maintenance: false,
      //
      is_script_loading: false,
      vcChatConfig: false
    }
  },

  methods: {
    checkRoute () {
      this.isRoot = this.$route.path === '/' ? true : false
    },
    load_script () {
      let self = this
      return new Promise((resolve) => {

        // if script is already loading via another component
        if ( self.is_script_loading ){
          // Resolve when the other component has loaded the script
          this.$root.$on('script_loaded', resolve)
          return
        }

        let script = document.createElement('script')
        script.setAttribute('id', 'vcLoaderScript')
        script.setAttribute('src', 'https://cdn.visitor.chat/vc-loader.min.js')
        script.async = true

        this.$root.$emit('loading_script')

        script.onload = () => {
          /* emit to global event bus to inform other components
           * we are already loading the script */
          this.$root.$emit('script_loaded')
          this.vcSetUp()
          resolve()
        }

        document.body.appendChild(script)

      })

    },

    vcSetUp () {
      window.VC_SETTINGS = {
        id: this.vcChatConfig.id,
        name: this.vcChatConfig.name,
        accountId: this.vcChatConfig.accountId,

        sbText: this.vcChatConfig.sbText,
        ctaTitle: this.vcChatConfig.sbTitle,
      }

      this.use_script()
    },

    async use_script () {
      try {
        await this.load_script()
        // .. do what you want after script has loaded
      } catch (err) { console.error(err) }

    }
  },

  created () {
    this.checkRoute()

    this.$store.watch(() => this.$store.getters.getVcChatConfig, res => {
      if (res && res.id && res.is !== '' && res.status && res.status !== 0 && !this.is_script_loading) {
        this.vcChatConfig = res

        setTimeout(() => {
          this.vcSetUp()
        }, 1);
      }
    }, {
      immediate: true
    })

    getData('core/getSiteSetup').then(result => {
      if (result.data && result.data.genSettings) {
        if (result.data.genSettings && result.data.genSettings.maintenance) {
          this.maintenance = result.data.genSettings.maintenance === '1'
        }

        if (this.maintenance) {
          this.fadeout = true
          this.loaded = true
        } else {
          this.$store.commit('changeGenSettings', result.data.genSettings)
          this.$store.commit('changeMainMenu', result.data.menu)
          this.$store.commit('setVcChatConfig', result.data.vcChatConfig)
          this.footerMenu = result.data.footer

          this.$forceUpdate()

          setTimeout(() => {
            this.fadeout = true
          }, 2000)

          setTimeout(() => {
            this.loaded = true
          }, 2500)
        }
      }
    })
  },

  watch:{
    $route () {
      this.checkRoute()
    }
  },
  mounted() {
    // window.VC_SETTINGS = {
    //   id: "www.theashtreecollection.co.uk",
    //   name: 'The Ashtree Collection',
    //   accountId: 643308,
    //   sbText: 'Live Chat\n\n"We\'re online and ready to chat with you now"',
    //   ctaTitle: 'Live Chat - We\'re Online',
    // }

    this.$root.$on('loading_script', () => { this.is_script_loading = true })

    // this.load_script()
  },
}
</script>


<style lang="scss">
@import '../scss/styles';

.site-maintenance {
  align-items: center;
  background-image: url('./assets/section-4.jpg');
  background-position: 50%;
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
  width: 100vw;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.15s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

$size: 250;

.app-loading {
  @include animate(all, .25s);
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  opacity: 1;
  width: 100vw;

  &.fadeout {
    opacity: 0;
  }

  .loading-icon {
    height: #{$size / 2.5}px;
    width: #{$size}px;
    background: linear-gradient(45deg, #fff, #333, #777, #fff, #fff);
    background-size: 1000% 1000%;
    animation: Gradient 6s ease infinite;
  }
}

@include keyframes(Gradient) {
  0%{background-position:50% 0%}
  50%{background-position:51% 100%}
  100%{background-position:50% 0%}
}
</style>
