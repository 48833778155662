<template>
  <div class="news-card" v-if="info">
    <div
      class="news-card-media__image"
      v-if="info.news_hero && info.news_hero.length > 0 && info.news_hero[0].type === 'image'"
      :style="{ 'background-image': 'url(/uploads' + info.news_hero[0].file + ')' }"
    ></div>
    <div class="news-card__overlay"></div>
    <div class="news-card__info">
      <div class="news-card__info-inner">
        <div class="news-card__info-title">{{info.news_title}}</div>

        <div class="news-card__extras">
          <div class="news-card__extras-headline">
            <ul class="news-card__tags-list">
              <li
                v-for="(tag, key) in info.news_tags"
                :key="key"
                class="news-card__tags-item">
                {{tagsArray[tag]}}
              </li>
            </ul>
            <div class="news-card__info-date">{{info.news_date | unix-date-only}}</div>
          </div>
          <div v-if="news_url" class="news-card__footer">
            <router-link :to="news_url" class="btn btn--outline">read more</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'news-card',
  props: {
    article: {
      type: Object
    },
    tagsArray: {}
  },

  data() {
    return {
      info: false,
      news_url: false
    }
  },

  created () {
    this.$store.watch(() => this.$store.getters.genSettings, res => {
      if (res) {
        if (res.news_url) {
          this.news_url = res.news_url + this.article.news_url
        }
      }
    }, {
      immediate: true
    })

    if (Object.keys(this.article).length > 0) {
      let info = this.article
      if (typeof info.news_content === 'string') {
        info.news_content = JSON.parse(info.news_content)
      }
      if (typeof info.news_hero === 'string') {
        info.news_hero = JSON.parse(info.news_hero)
      }
      if (typeof info.news_tags === 'string') {
        info.news_tags = JSON.parse(info.news_tags)
      }

      this.info = info
      this.$forceUpdate()
    }
  }
}
</script>
