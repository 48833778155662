import api from './api.config'
import forEach from 'lodash/forEach'
import { EventBus } from '../helper/event-bus'

export const getSiteNav = () => api(`sitenav`)

// ADMIN
export const getData = (url) => api(url)

export const getDataProgress = (url) => api(url, {
  onDownloadProgress: function(progressEvent) {
    EventBus.$emit('get-progress', progressEvent)
  }
})

export const getPostId = (url, id) => {
  let data = new FormData()

  data.append('id', id)
  return api.post(url, data)
}

export const postData = (url, dataArr) => {
  let data = new FormData()

  forEach(dataArr, (item) => {
    data.append(item.id, item.model)
  })

  return api.post(url, data)
}

export const postDataObj = (url, dataArr) => {
  let data = new FormData()

  forEach(dataArr, (item, key) => {
    data.append(key, item)
  })

  return api.post(url, data)
}
