import Vue from 'vue'
import Router from 'vue-router'
import Content from '../views/content'

Vue.use(Router)

export default new Router({
  mode: 'history',
  // scrollBehavior (to, from, savedPosition) {
  //   return { x: 0, y: 0 }
  // },
  scrollBehavior(to) {
    if (to.hash) {
      return window.scrollTo({ top: document.querySelector(to.hash).offsetTop, behavior: 'smooth' });
    }
    return window.scrollTo({ top: 0, behavior: 'smooth' });
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Content
    },
    {
      path: '/:url',
      name: 'Content',
      component: Content
    },
    {
      path: '/:url/:var',
      name: 'ContentPlus',
      component: Content
    },
    {
      path: '/:url/:vara/:varb',
      name: 'ContentExtra',
      component: Content
    },
    {
      path: '/:url/:vara/:varb/:varc',
      name: 'ContentExtraPlus',
      component: Content
    }
  ]
})
