<template>
  <div
    v-if="loaded && media && typeof media !== 'string' && media.length > 0"
    class="car-card-media">
    <div v-for="(item, key) in media"
      :key="key"
      :class="[
        active === key ? 'is-active': ''
      ]"
      :data-slides="key"
      class="car-card-media__inner">
      <div
        class="car-card-media__image"
        v-if="item.type === 'image'"
        :style="{ 'background-image': 'url(/uploads' + item.file + ')' }"
      ></div>
    </div>
  </div>
  <div v-else-if="!loaded">Loading</div>
</template>

<script>
export default {
  props: {
    media: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  data () {
    return {
      loaded: false,
      theInterval: false,
      active: 0,
      index: 0,
      timing: 10000
    }
  },

  methods: {
    runSlideshow () {
      let self = this
      self.theInterval = setInterval(() => {
        self.index++
        if (self.index === self.media.length) {
          self.index = 0
        }
        self.active = self.index
      }, self.timing)
    }
  },

  created () {
    let self = this
    let ran = Math.floor(Math.random() * 6) * 200
    setTimeout(() => {
      self.runSlideshow()
    }, ran)

    this.loaded = true
  },

  destroyed () {
    clearInterval(this.theInterval)
  }
}
</script>

<style lang="scss">
@import '../../../scss/init';
$carMediaTiming: 1.5s;
.car-card-media {
  @include animate(all);
  display: block;
  height: 100%;
  position: relative;
  width: 100%;
}

.car-card-media__inner {
  @include animate(all, $carMediaTiming);
  filter: grayscale($image_greyscale);
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &.is-active {
    opacity: 1;
  }
}

.car-card-media__image {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
</style>
