<template>
  <div class="inventory" v-if="items.length > 0">
    <h2 class="pb-3">Related Vehicles</h2>
    <div class="inventory-list">
      <div
        class="inventory-card"
        v-for="(car, key) in items"
        :key="key">
        <carCard
          :car="car"
          :active="false"
          v-if="car && car.inv_id" />
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../helper/event-bus'
import carCard from '../car-card/car-card'
export default {
  name: 'related-vehicles',

  props: {
    colour: {
      type: String
    }
  },

  components: { carCard },

  data() {
    return {
      items: []
    }
  },

  created () {
    EventBus.$on('inventory-related', list => {
      if (list.state) {
        this.items = list.data
      }
    })
  }
}
</script>

<style lang="scss" scoped>

</style>