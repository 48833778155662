export default {
  methods: {
    setMeta (title, content, hero) {
      title = title.replace('%s', 'The Ashtree Collection')
      let url = window.location.origin + window.location.pathname

      // let desc = { name: 'description', content: this.truncate(content, 200) }
      // meta.push(desc)
      let meta = [
        { vmid: 'description', name: 'description', content: this.truncate(content, 200) },
        { property: 'og:title', content: title },
        { property: 'og:site_name', content: 'The Ashtree Collection' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: url },
        { property: 'og:description', content: this.truncate(content, 200) },

        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:site', content: url },
        { name: 'twitter:title', content: title },
        { name: 'twitter:description', content: this.truncate(content, 200) },

        { itemprop: 'name', content: title },
        { itemprop: 'description', content: this.truncate(content, 200) }
      ]

      if (hero && hero.length > 0) {
        //   {property: 'og:url', content: 'https://www.my-site.com/my-special-page'},
        //   {property: 'og:image', content: 'https://www.my-site.com/my-special-image.jpg'},
        meta.push({
          property: 'og:image', content: window.location.origin + '/uploads' + hero[0].file
        })
        meta.push({
          name: 'twitter:image:src', content: window.location.origin + '/uploads' + hero[0].file
        })
        meta.push({
          itemprop: 'image', content: window.location.origin + '/uploads' + hero[0].file
        })
      }

      return meta
    },
    truncate (text, length, clamp) {
      text = text || ''
      clamp = clamp || ''
      length = length || 30

      if (text.length <= length) {
        return text
      }

      let tcText = text.slice(0, length - clamp.length)
      let last = tcText.length - 1

      while (last > 0 && tcText[last] !== ' ' && tcText[last] !== clamp[0]) last -= 1

      // Fix for case when text dont have any `space`
      last = last || length - clamp.length

      tcText = tcText.slice(0, last)

      return tcText + clamp
    }
  }
}

// meta: [
//   ...
//   // OpenGraph data (Most widely used)
//   {property: 'og:title', content: 'My Page Title ← My Site'},
//   {property: 'og:site_name', content: 'My Site'},
//   // The list of types is available here: http://ogp.me/#types
//   {property: 'og:type', content: 'website'},
//   // Should the the same as your canonical link, see below.
//   {property: 'og:url', content: 'https://www.my-site.com/my-special-page'},
//   {property: 'og:image', content: 'https://www.my-site.com/my-special-image.jpg'},
//   // Often the same as your meta description, but not always.
//   {property: 'og:description', content: 'I have things here on my site.'}

//   // Twitter card
//   {name: 'twitter:card', content: 'summary'},
//   {name: 'twitter:site', content: 'https://www.my-site.com/my-special-page'},
//   {name: 'twitter:title', content: 'My Page Title ← My Site'},
//   {name: 'twitter:description', content: 'I have things here on my site.'},
//   // Your twitter handle, if you have one.
//   {name: 'twitter:creator', content: '@alligatorio'}
//   {name: 'twitter:image:src', content: 'https://www.my-site.com/my-special-image.jpg'},

//   // Google / Schema.org markup:
//   {itemprop: 'name', content: 'My Page Title ← My Site'},
//   {itemprop: 'description': 'I have things here on my site.'},
//   {itemprop: 'image', content: 'https://www.my-site.com/my-special-image.jpg'}
// ]
