<template>
  <div>
    <section
      v-for="(item, key) in details.inv_info"
      :key="key"
      class="inventory-details"
      :class="[
        'is-' + item.config
      ]">
      <h2
        v-if="item.title !== ''"
        class="display-1 pb-3"
        v-text="item.title"
      />

      <div
        v-if="!item.html"
        class="inventory-section__wrapper"
        :class="[
          'is-' + item.config
        ]">
        <div class="inventory-media">
          <inventorySectionMedia :media="item.media" :config="item.config" />
        </div>
        <div
          class="inventory-content rte"
          :class="[
            'is-' + item.config
          ]"
          v-html="item.content"></div>
      </div>

      <div
        v-if="item.html && item.content !== ''"
        class="inventory-section__wrapper"
      >
        <div v-if="!validVideoId(item.content)" class="inventory-video">
          <iframe :src="'https://www.youtube.com/embed/' + item.content + '?modestbranding=1&loop=0&rel=0'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>

      <v-flex sm12 py-3 px-5 v-if="key !== (details.inv_info.length - 1)"><v-divider color="white" /></v-flex>
    </section>

  </div>
</template>

<script>
import inventorySectionMedia from '../inventory-section-media/inventory-section-media'
import { getPostId } from '../../api/api'
import { EventBus } from '../../helper/event-bus'
export default {
  name: 'vehicle-details',

  props: {
    colour: {
      type: String
    }
  },

  components: {inventorySectionMedia},

  data() {
    return {
      details: false
    }
  },

  methods: {
    filter (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    validVideoId (id) {
      const img = new Image()
      img.src = "http://img.youtube.com/vi/" + id + "/mqdefault.jpg"
      img.onload = function () {
        return parseInt(this.width) > 0
      }
    },
    money (value) {
      if (!value) {
        return false
      }
      value = parseInt(value).toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP',
      })

      let test = value.split('.')
      return test[0]
    },
  },

  created () {
    let inv_id = this.$route.params.vara

    getPostId('inventory/getInventoryDetailsFE', inv_id).then(results => {
      let response = {}
      let related = []
      let hero = []
      response = results.data.details
      related = results.data.related
      response.inv_info = results.data.details.inv_sold === '1' ? [] : JSON.parse(results.data.details.inv_info)
      response.inv_media = JSON.parse(results.data.details.inv_media)
      let title = this.filter(response.inv_make)

      let subtitle =  this.filter(response.inv_model) + ' ' + response.inv_variant
      EventBus.$emit('update-page-title', title)
      EventBus.$emit('update-page-subtitle', subtitle)

      let subtitleData = {}

      if (response.inv_colour) {
        subtitleData.colour = this.filter(response.inv_colour)
      }

      response.inv_media_option_a = response.inv_media_option_a === '1'

      if (response.inv_price && response.inv_price > 0) {
        subtitleData.price = this.money(response.inv_price)
      }

      if (response.inv_mileage) {
        subtitleData.mileage = response.inv_mileage
      }

      if (response.inv_year) {
        subtitleData.year = response.inv_year
      }

      if (response.inv_engine) {
        subtitleData.engine = response.inv_engine
      }

      if (response.inv_transmission) {
        subtitleData.transmission = response.inv_transmission
      }

      if (response.inv_sold === '1') {
        subtitleData.sold = 'Sold'
      }

      // subtitleData.mileage = response.inv_mileage
      // subtitleData.year = response.inv_year || false

      EventBus.$emit('inventory-datails', {state: true, data: subtitleData})

      EventBus.$emit('inventory-related', {state: true, data: related})

      if (response.inv_media.length > 0) {
        const blurOption = response.inv_media_option_a
        response.inv_media.forEach(item => {
          hero.push({
            file: item.path + item.file,
            type: 'image',
            id: item.id
          })
        });

        if (hero.length > 0) {
          EventBus.$emit('updateHeroContent', { items: hero, blurOption: blurOption})
        }
      }

      this.details = response
    })

    this.$store.watch(() => this.$store.getters.genSettings, res => {
      if (res) {
        this.vcChatDetails = res.vcChatDetails
      }
    }, {
      immediate: true
    })
  },

  destroyed () {
    // EventBus.$emit('hero-search', false)
  }
}
</script>
