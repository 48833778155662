<template>
<div>
  <v-layout v-if="formState === 0" wrap class="subscribe__wrapper">
    <v-flex xs12 mt-4>
      <div class="subscribe__text">
        <span class="headline" v-text="panelTitle" />
      </div>
    </v-flex>
    <v-flex xs12 sm6 md3 mt-4 px-2>
       <v-text-field
          :dark="colour !== 'white'"
          :light="colour === 'white'"
          v-model="formData.name"
          outline
          :error-messages="nameErrors"
          required
          :disabled="formloading"
          @blur="$v.formData.name.$touch()"
          type="text"
          label="First Name*" />
    </v-flex>
    <v-flex xs12 md3 sm6 mt-4 px-2>
       <v-text-field
          :dark="colour !== 'white'"
          :light="colour === 'white'"
          v-model="formData.last"
          outline
          required
          :disabled="formloading"
          @blur="$v.formData.last.$touch()"
          type="text"
          label="Last Name" />
    </v-flex>
    <v-flex xs12 md4 mt-4 px-2>
      <v-text-field
        :dark="colour !== 'white'"
        :light="colour === 'white'"
        v-model="formData.email"
        outline
        :error-messages="emailErrors"
        required
        :disabled="formloading"
        @blur="$v.formData.email.$touch()"
        type="email"
        label="Email Address*" />
    </v-flex>
    <v-flex xs12 md2 mt-4 px-2>
      <v-btn
        outline
        class="subscribe-btn py-2 mx-0 mb-4"
        :dark="colour !== 'white'"
        :light="colour === 'white'"
        :loading="formloading"
        @click="subscribeAction()"
      >Subscribe</v-btn>
    </v-flex>
  </v-layout>

  <v-layout v-if="formState === 1" wrap class="subscribe__wrapper">
    <v-flex xs12 mt-4>
      <div class="subscribe__text">
        <span class="headline" v-text="'Thank you for signing up.'" />
      </div>
    </v-flex>
  </v-layout>
  <v-layout v-if="formState === 2" wrap class="subscribe__wrapper">
    <v-flex xs12 mt-4>
      <div class="subscribe__text">
        <span class="headline" v-text="'You appear to be on our list.'" />
      </div>
    </v-flex>
  </v-layout>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { postDataObj } from '../../api/api'

export default {
  name: 'mailerliteSignup',
  mixins: [validationMixin],
  props: {
    colour: {
      type: String
    },
    params: {}
  },
  validations: {
    formData: {
      // name: { required },
      email: { required, email },
      name: { required },
      last: {}
    }
  },
  computed: {
    nameErrors () {
      const errors = []
      if (!this.$v.formData.name.$dirty) return errors
      !this.$v.formData.name.required && errors.push('Please enter your name.')
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.formData.email.$dirty) return errors
      !this.$v.formData.email.email && errors.push('Must be valid e-mail')
      !this.$v.formData.email.required && errors.push('Please enter your email.')
      return errors
    }
  },
  //
  data: () => ({
    formData: {
      email: '',
      name: '',
      last: ''
    },
    panelTitle: 'Sign up to our mailing list',
    formloading: false,
    formState: 0
  }),
  created () {
    if (this.params.panelTitle) {
      this.panelTitle = this.params.panelTitle
    }
  },
  methods: {
    subscribeAction () {
      // mailerliteSubscribe
      this.$v.$touch()

      if (!this.$v.$error) {
        this.formloading = true
        postDataObj('mailerlite/mailerliteSubscribe', this.formData).then(response => {
          this.formloading = false
          if (response.data) {
            if (response.data.status) {
              this.formState = 2
            } else {
              this.formState = 1
            }
          }

          this.formData = {
            email: '',
            name: '',
            last: ''
          }
          this.$v.formData.$reset()

          setTimeout(() => {
            this.formState = 0
          }, 3000)
        })
      }
    }
  }
}
</script>
