<template>
  <div class="news">
    <!-- {{news}} -->
    <div class="news-list" :key="dataKey" v-if="news.length > 0">
      <div
        class="news-list__card"
        v-for="(article, key) in news"
        :key="key">
        <news-card :article="article" :tagsArray="tags" />
      </div>
    </div>
  </div>
</template>

<script>
import { getData } from '../../api/api.js'
import { EventBus } from '../../helper/event-bus'
import newsCard from '../news-card/news-card'
export default {
  name: 'news-list',

  components: { newsCard },

  data() {
    return {
      dataKey: 0,
      tags: [],
      news: []
    }
  },

  created () {
    getData('getNewsTags').then(result => {
      if (result.data) {
        this.tags = result.data
      }
    })

    getData('news/getNewsListFE').then(result => {
      if (result.data) {
        this.news = result.data.list
        // EventBus.$emit('news-filters', {state: true, data: result.data.list})
        this.dataKey = new Date().getTime()
      }
    })
  },

  destroyed () {
    EventBus.$emit('news-filters', false)
  }
}
</script>
