<template>
  <inventory-list v-if="model === 'inventory-list'" :colour="colour" />
  <inventory-sold-list v-else-if="model === 'inventory-sold-list'" :colour="colour" />
  <vehicle-details v-else-if="model === 'vehicle-details'" :colour="colour" />
  <related-vehicles v-else-if="model === 'related-vehicles'" :colour="colour" />
  <homepage-search v-else-if="model === 'homepage-search'" :colour="colour" />
  <contact-form v-else-if="model === 'contact-form'" :colour="colour"  :params="params" />
  <concierge-sellers-form v-else-if="model === 'concierge-sellers-form'" :colour="colour" :params="params" />
  <concierge-buyers-form v-else-if="model === 'concierge-buyers-form'" :colour="colour" :params="params" />
  <car-enquiry-form v-else-if="model === 'car-enquiry-form'" :colour="colour" :params="params" />
  <news-list v-else-if="model === 'news-list'" :colour="colour" />
  <news-article v-else-if="model === 'news-article'" :colour="colour" />
  <partners-list v-else-if="model === 'partners-list'" :colour="colour" :params="params" />
  <testimonial-list v-else-if="model === 'testimonial-list'" :colour="colour" :params="params" />
  <mailerlite-signup v-else-if="model === 'mailerlite-signup'" :colour="colour" :params="params" />
  <div v-else>{{params.params.model}}</div>
</template>

<script>
import inventoryList from '../dynamic/inventory-list'
import inventorySoldList from '../dynamic/inventory-sold-list'
import vehicleDetails from '../dynamic/vehicle-details'
import relatedVehicles from '../dynamic/related-vehicles'
import homepageSearch from '../dynamic/homepage-search'
import contactForm from '../dynamic/contact-form'
import conciergeSellersForm from '../dynamic/concierge-sellers-form'
import conciergeBuyersForm from '../dynamic/concierge-buyers-form'
import carEnquiryForm from '../dynamic/car-enquiry-form'
import newsList from '../dynamic/news-list'
import newsArticle from '../dynamic/news-article'
import partnersList from '../dynamic/partners-list'
import testimonialList from '../dynamic/testimonial-list'
import mailerliteSignup from '../dynamic/mailerlite-signup'
export default {
  components: {
    inventoryList,
    inventorySoldList,
    vehicleDetails,
    relatedVehicles,
    homepageSearch,
    contactForm,
    conciergeSellersForm,
    conciergeBuyersForm,
    carEnquiryForm,
    newsList,
    newsArticle,
    partnersList,
    testimonialList,
    mailerliteSignup
  },
  name: 'dynamicItems',
  props: {
    params: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      model: false,
      item: false,
      colour: 'black'
    }
  },
  created () {
    this.model = this.params.params.model
    this.item = this.params

    this.colour = this.item.background.model ? this.item.background.model : 'black'
  }
}
</script>
