import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    genSettings: {},
    vcChatConfig: false,
    mainMenu: [],
    heroCast: false
  },
  mutations: {
    changeGenSettings (state, genSettings) {
      state.genSettings = genSettings
    },
    setVcChatConfig (state, vcChatConfig) {
      if (vcChatConfig.details) {
        vcChatConfig.details = parseInt(vcChatConfig.details)
      }
      if (vcChatConfig.home) {
        vcChatConfig.home = parseInt(vcChatConfig.home)
      }
      if (vcChatConfig.status) {
        vcChatConfig.status = parseInt(vcChatConfig.status)
      }
      state.vcChatConfig = vcChatConfig
    },
    changeMainMenu (state, menu) {
      state.mainMenu = menu
    },
    changeHeroCast (state, cast) {
      state.heroCast = cast
    },
  },
  getters: {
    genSettings: state => state.genSettings,
    getVcChatConfig: state => state.vcChatConfig,
    heroCast: state => state.heroCast,
    mainMenu: state => state.mainMenu,
  }
})
