<template>
  <div class="inventory">
    <div class="inventory-list" :key="dataKey">
      <div
        class="inventory-card"
        v-for="(car, key) in filteredItems"
        :key="key">
        <carCard
          :car="car"
          :active="activeSummary"
          v-if="car && car.inv_id"
          @active-summary-car="updateActiveSummary" />
      </div>
    </div>
    <div
      class="inventory-list-updating"
      :class="[
        updating ? 'is-active' : ''
      ]">
      Updating
    </div>
  </div>
</template>

<script>
import { getData } from '../../api/api.js'
import { EventBus } from '../../helper/event-bus'
import carCard from '../car-card/car-card'
export default {
  name: 'inventiry-list',

  props: {
    colour: {
      type: String
    }
  },

  components: { carCard },

  data () {
    return {
      items: [],
      filteredItems: [],
      updating: false,
      dataKey: 0,
      activeSummary: -1,
    }
  },

  methods: {
    filterMakes (make) {
      this.filteredItems = this.items.filter(x => {
        return x.inv_make === make
      })
      this.activeSummary = -1
    },
    filterModels (make, model) {
      this.filteredItems = this.items.filter(x => {
        return x.inv_make === make && x.inv_model === model
      })
      this.activeSummary = -1
    },
    filterAll (make, model, variant) {
      this.filteredItems = this.items.filter(x => {
        return x.inv_make === make && x.inv_model === model && x.inv_variant === variant
      })
      this.activeSummary = -1
    },
    filtersReset () {
      this.filteredItems = JSON.parse(JSON.stringify(this.items))
      this.activeSummary = -1
    },
    //
    updateActiveSummary (evt) {
      if (this.activeSummary === evt.inv_id) {
        this.activeSummary = -1
      } else {
        this.activeSummary = evt.inv_id
      }
    }
  },

  created () {
    getData('inventory/getInventoryListFE').then(result => {
      if (result.data) {
        this.items = result.data
        this.filteredItems = JSON.parse(JSON.stringify(result.data))
      }
    })

    getData('inventory/getInventoryFilters').then(result => {
      if (result.data) {
        EventBus.$emit('inventory-filters', {state: true, data: result.data})
      }
    })

    EventBus.$on('updateInventoryFilters', filters => {
      this.updating = true
      if (filters.make && filters.model && filters.variant) {
        this.filterAll(filters.make, filters.model, filters.variant)
      } else if (filters.make && filters.model && !filters.variant) {
        this.filterModels(filters.make, filters.model)
      } else if (filters.make && !filters.model && !filters.variant) {
        this.filterMakes(filters.make)
      } else {
        this.filtersReset()
      }
    })
  },

  destroyed () {
    EventBus.$emit('inventory-filters', false)
  },
  watch: {
    filteredItems: {
      handler () {
        this.dataKey = new Date().getTime()
        setTimeout(() => {
          this.updating = false
          EventBus.$emit('updateInventoryFinished', false)
        }, 500)
      }
    }
  }
}
</script>

<style>

</style>
