<template>
  <div
    v-if="items"
    class="content-container">
    <div
      class="content-wrapper"
      :class="[
        item.type === 'media' ? 'is-media' : '',
        item.background.model ? 'content-bg-' + item.background.model : ''
      ]"
      v-for="(item, key) in items" :key="key">
      <div
        class="layout-wrapper"
        :class="[
          item.sidebarOption.model !== 'none' ? 'has-sidebar' : '',
          item.sidebarOption.model === 'left' ? 'is-left' : '',
          item.sidebarOption.model === 'right' ? 'is-right' : '',
          item.type === 'text' ? 'plain-text' : ''
        ]">
        <div class="layout-main">
          <p class="rte" v-if="item.type === 'text'" v-html="parseText(item.content)"></p>
          <mediaContainer v-if="item.type === 'media'" :media="item.content" />
          <dynamic v-if="item.type === 'dynamic'" :params="item"></dynamic>
        </div>
        <div class="layout-sidebar" v-if="item.sidebarOption.model !== 'none'">
          <p class="rte" v-if="item.sidebar.type === 'text'" v-html="parseText(item.sidebar.content)"></p>
          <mediaContainer v-if="item.sidebar.type === 'media'" :media="item.sidebar.content" />
          <dynamic v-if="item.sidebar.type === 'dynamic'" :params="item.sidebar"></dynamic>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mediaContainer from '../media-container/media-container'
import dynamic from './dynamic'
import anchorme from 'anchorme'
export default {
  components: {
    mediaContainer,
    dynamic
  },
  name: 'content-view',
  props: {
    content: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      items: false
    }
  },
  methods: {
    parseText (text) {
      let result = anchorme(
        text,
        {
          attributes: [
            {
              name: 'target',
              value: '_blank'
            }
          ]
        }
      )

      return result || text
    }
  },
  created () {
    let newItems = []
    if (this.content) {
      let items = this.content
      items.forEach(item => {
        let newItem = {}
        if (item.type === 'text') {
          newItem = parseText(item)
        } else if (item.type === 'dynamic') {
          newItem = {
            content: item.content,
            type: item.type,
            params: item.params,
            gallery: item.galleryParams,
            background: item.background,
            sidebarOption: item.sidebarOption,
            sidebar: {},
            panelTitle: item.panelTitle ? item.panelTitle : false,
            panelOption: item.panelOption ? item.panelOption : false
          }
        } else {
          newItem = {
            content: item.content,
            type: item.type,
            background: item.background,
            sidebarOption: item.sidebarOption,
            sidebar: {}
          }
        }

        // let sidebar = item.sidebar

        if (item.sidebar.type === 'dynamic') {
          newItem.sidebar = {
            content: item.sidebar.content,
            type: item.sidebar.type,
            params: item.sidebar.params,
            panelTitle: item.sidebar.panelTitle ? item.sidebar.panelTitle : false
          }
        } else {
          newItem.sidebar = {
            content: item.sidebar.content,
            type: item.sidebar.type
          }
        }

        newItems.push(newItem)
      })

      this.items = newItems
    }
  }
}

const parseText = (item) => {
  let result = {
    content: item.content,
    type: item.type,
    background: item.background,
    sidebarOption:item.sidebarOption,
    sidebar: {}
  }
  return result
}
</script>

// <style lang="scss">
// @import '../../../scss/init';

// .content-container {
//   padding: $space-std / 2;

//   @include media-min-sm {
//     padding: $space-std;
//   }

//   @include media-max-sm {
//     padding: $space-std * 2;
//   }

//   .content-container {
//     padding: 0;
//   }

//   .content-wrapper {
//     margin-bottom: $space-std * 2;
//     position: relative;

//     &:last-of-type {
//       margin-bottom: $space-std;
//     }

//     &.is-media {
//       height: 0;
//       padding-bottom: 57%;
//     }
//   }
// }
// </style>
