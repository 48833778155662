<template>
  <router-link :to="details_url" ref="carcard" class="inventory-card-inner" v-if="item">
    <div class="inventory-card__media">
      <div :to="details_url" class="inventory-card__media-wrapper">
        <carMedia v-if="media" :media="media" />
      </div>
    </div>
    <div class="inventory-card__title">{{item.inv_make | capitalize}} {{item.inv_model | capitalize}} {{item.inv_variant}}</div>
    <div v-if="item.inv_teaser && item.inv_sold !== '1'" class="inventory-card__teaser">{{item.inv_teaser | truncate(80)}}</div>
    <!-- <div class="inventory-card__footer">
      <router-link :to="details_url" class="btn btn--outline">View</router-link>
      <button class="btn btn--outline" @click="showSummary">summary</button>
    </div> -->

    <!-- <div
      class="inventory-card__summary"
      :class="[
        active === item.inv_id ? 'is-active' : ''
      ]">
      <div class="inventory-card__summary-inner">
        <div
          v-for="(info, key) in item.inv_info"
          :key="key"
          class="">
          <h3 class="title pt-2 pb-1">{{info.title}}</h3>
          <div
            class=" rte"
            v-html="info.content"></div>
          <v-divider v-if="key !== (item.inv_info.length - 1)" class="ma-3" color="white" />
        </div>
      </div>
      <div class="inventory-card__summary-footer">
        <button class="btn btn--block btn--outline" @click="showSummary">close</button>
      </div>
    </div> -->
  </router-link>
</template>

<script>
import carMedia from './car-card-media'

export default {
  name: 'car-card',

  components: {
    carMedia
  },
  props: {
    car: {
      type: Object,
      default: function () {
        return {}
      }
    },
    active: {}
  },

  data () {
    return {
      item: false,
      teaser: false,
      media: false,
      details_url: false,
      summaryView: false
    }
  },

  methods: {
    slugify (str) {
      str = str.replace(/^\s+|\s+$/g, ''); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
      let to   = "aaaaeeeeiiiioooouuuunc------";
      for (let i = 0, l = from.length; i < l ; i++) {
          str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
          .replace(/\s+/g, '-') // collapse whitespace and replace by -
          .replace(/-+/g, '-'); // collapse dashes

    return str;
    },

    showSummary () {
      this.summaryView = true
      this.$emit('active-summary-car', this.item)
    }
  },

  created () {
  },

  mounted () {
    let title = this.slugify(this.car.inv_make + ' ' + this.car.inv_model + ' ' + this.car.inv_variant + ' ' + this.car.inv_year)
    let url = '/' + this.car.inv_id + '/' + title
    this.$store.watch(() => this.$store.getters.genSettings, res => {
      if (res) {
        if (res.details_url) {
          this.details_url = res.details_url + url
        }
      }
    }, {
      immediate: true
    })

    if (this.car && this.car.inv_id) {
      let self = this
      let car = this.car
      let media = []
      let mediaArr = []
      if (typeof car.inv_media === 'string') {
        car.inv_media = JSON.parse(car.inv_media)
      }

      if (typeof car.inv_info === 'string') {
        car.inv_info = JSON.parse(car.inv_info)
      }

      if (car.inv_media.length > 4) {
        media = car.inv_media.slice(0, 4)
      } else {
        media = car.inv_media
      }

      media.forEach(item => {
        mediaArr.push({
          type: item.type,
          file: item.path + 'thumbnail_' + item.file
        })
      });

      setTimeout(() => {
        self.media = mediaArr
        self.item = car
      }, 1)
    }
  },

  destroyed () {
    this.item = false,
    this.teaser = false,
    this.media = false
  }
}
</script>
