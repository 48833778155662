var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items)?_c('div',{staticClass:"content-container"},_vm._l((_vm.items),function(item,key){return _c('div',{key:key,staticClass:"content-wrapper",class:[
      item.type === 'media' ? 'is-media' : '',
      item.background.model ? 'content-bg-' + item.background.model : ''
    ]},[_c('div',{staticClass:"layout-wrapper",class:[
        item.sidebarOption.model !== 'none' ? 'has-sidebar' : '',
        item.sidebarOption.model === 'left' ? 'is-left' : '',
        item.sidebarOption.model === 'right' ? 'is-right' : '',
        item.type === 'text' ? 'plain-text' : ''
      ]},[_c('div',{staticClass:"layout-main"},[(item.type === 'text')?_c('p',{staticClass:"rte",domProps:{"innerHTML":_vm._s(_vm.parseText(item.content))}}):_vm._e(),(item.type === 'media')?_c('mediaContainer',{attrs:{"media":item.content}}):_vm._e(),(item.type === 'dynamic')?_c('dynamic',{attrs:{"params":item}}):_vm._e()],1),(item.sidebarOption.model !== 'none')?_c('div',{staticClass:"layout-sidebar"},[(item.sidebar.type === 'text')?_c('p',{staticClass:"rte",domProps:{"innerHTML":_vm._s(_vm.parseText(item.sidebar.content))}}):_vm._e(),(item.sidebar.type === 'media')?_c('mediaContainer',{attrs:{"media":item.sidebar.content}}):_vm._e(),(item.sidebar.type === 'dynamic')?_c('dynamic',{attrs:{"params":item.sidebar}}):_vm._e()],1):_vm._e()])])}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }