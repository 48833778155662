<template>
  <div></div>
</template>

<script>
import { EventBus } from '../../helper/event-bus'
export default {
  name: 'homepage-search',

  created () {
    EventBus.$emit('hero-search', true)
  },

  destroyed () {
    EventBus.$emit('hero-search', false)
  }
}
</script>
