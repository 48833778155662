<template>
  <div ref="carousel" :class="[
      preview && carousel ? 'carousel' : '',
      blurOption ? 'media-wrapper__with-blur' : 'media-wrapper'
    ]">
    <div
      v-if="media && blurOption && media.length > 0"
      class="media-wrapper__blurred"
    >
      <lazy-background
        :image-source="'/uploads' + media[0].file"
        :loadingImage="loadingImage"
        error-image="/img/error.png"
        image-class="media-wrapper__image is-blurred"
      />
    </div>
    <!-- {{typeof media}} -->
    <ul
      v-if="media && typeof media !== 'string' && media.length > 0"
      ref="imageContainer"
      :class="[
        'media-wrapper__list',
        blurOption ? 'has-blur' : ''
      ]"
    >
      <li
        v-for="(item, key) in media"
        :key="key"
        :class="{'is-active': active === key}"
        :data-slides="key"
        class="media-wrapper__inner lazy-load"
      >
        <lazy-background
          v-if="!blurOption"
          :image-source="'/uploads' + item.file"
          :loadingImage="loadingImage"
          error-image="/img/error.png"
          image-class="media-wrapper__image"
        />

        <!-- <lazy-background
          v-if="blurOption"
          :image-source="'/uploads' + item.file"
          :loadingImage="loadingImage"
          error-image="/img/error.png"
          image-class="media-wrapper__image is-contain"
        /> -->
        <!-- <img
        /> -->
        <v-img
          v-if="blurOption"
          contain
          class="pointer grey darken-2"
          :src="'/uploads' + item.file"
        >
          <!-- <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              />
            </v-row>
          </template> -->
        </v-img>
        <div v-if="item.title" class="media-wrapper__title">{{item.title}}</div>
      </li>
    </ul>
    <!-- <ul class="media-dots" ref="indicators" v-if="preview && carousel">
      <li
        :class="{'is-active': active === k}"
        class="media-dot"
        v-for="(n, k) in this.media.length"
        :key="k"
        @click="goToIndex(k)"
        :data-slide-to="k"></li>
    </ul> -->
    <button
      v-if="navigation && carousel"
      @click="goToPrev"
      class="btn media-nav media-nav__prev">
      <icon class="media-nav-icon icon--white" iconName="chevron-left" />
    </button>
    <button
      v-if="navigation && carousel"
      @click="goToNext"
      class="btn media-nav media-nav__next">
      <icon class="media-nav-icon icon--white" iconName="chevron-right" />
    </button>
  </div>
</template>

<script>
import lazyBackground from '../lazy-background/lazy-background'
// import LazyLoad from 'vanilla-lazyload'
import icon from '../../components/icons/Icon.vue'

export default {
  components: { lazyBackground, icon },
  props: {
    media: {
      type: Array,
      default: function () {
        return []
      }
    },
    preview: {
      type: Boolean,
      default: true
    },
    hero: {
      type: Boolean,
      default: false
    },
    navigation: {
      type: Boolean,
      default: false
    },
    blurOption: {
      type: Boolean,
      default: false
    }
  },
  name: 'media-container',
  data () {
    return {
      carousel: false,
      active: 0,
      index: 0,
      mediaPlaying: false,
      sliding: false,
      timing: 7000,
      theInterval: false,
      loadingImage: '/assets/loading.svg'
    }
  },
  methods: {
    goToIndex (index) {
      clearInterval(this.theInterval)
      this.index = index
      if (index > this.active) {
        this.changeSlide(this.index, 'next')
      } else {
        this.changeSlide(this.index, 'prev')
      }

      this.runCarousel()
    },
    goToNext () {
      this.index++
      if (this.index === this.media.length) {
        this.index = 0
      }
      clearInterval(this.theInterval)
      this.changeSlide(this.index, 'next')
    },
    goToPrev () {
      if (this.index === 0) {
        this.index = this.media.length
      }
      this.index--
      clearInterval(this.theInterval)
      this.changeSlide(this.index, 'prev')
    },
    runCarousel () {
      this.theInterval = setInterval(() => {
        if (this.sliding) {
          return
        }
        if (this.mediaPlaying) {
          return
        }
        this.index++
        if (this.index === this.media.length) {
          this.index = 0
        }
        this.changeSlide(this.index, 'next')
      }, this.timing)
    },
    changeSlide (k, type) {
      let c = this.$refs.carousel
      // let i = this.$refs.indicators
      if (c && !this.sliding && !this.mediaPlaying) {
        this.sliding = true
        let direction = type === 'next' ? 'left' : 'right'
        let $thisSlide = c.querySelector('.is-active')
        let $nextSlide = c.querySelector('[data-slides="' + k + '"]')

        $nextSlide.classList.add(type)

        setTimeout(() => {
          $nextSlide.classList.add(direction)
          $thisSlide.classList.add(direction)
        })

        // i.querySelector('.is-active').classList.remove('is-active')
        // i.querySelector('[data-slide-to="' + k + '"]').classList.add('is-active')

        setTimeout(() => {
          $nextSlide.classList.remove(type)
          $nextSlide.classList.remove(direction)
          $thisSlide.classList.remove(direction)
          this.active = k
          this.sliding = false
        }, 600)
      }
    },
    //
    listenForKeys () {
      let self = this
      document.addEventListener('keydown', self.handlerArrowKeys)
    },

    stopListenForKeys () {
      let self = this
      document.removeEventListener('keydown', self.handlerArrowKeys)
    },

    handlerArrowKeys (evt) {
      var code = evt.which || evt.keyCode;
      if (code == '37') {
        // Left
        this.goToPrev()
      } else if (code == '39') {
        // Right
        this.goToNext()
      }
    }
  },
  mounted () {
    this.carousel = this.media.length > 1

    setTimeout(() => {
      if (this.carousel) {
        this.runCarousel()
        this.listenForKeys()
      }

      if (!this.hero && !this.carousel) {
        this.$refs.imageWrapper.forEach((item) => {
          let par = item.closest('.content-wrapper')
          getBackgroundSize(item, par)
        })
      }
    }, 1)

    // let containerImages = new LazyLoad({
    //   // container: this.$refs.imageContainer,
    //   elements_selector: '.lazy-load',
    //   load_delay: 3000
    // })
  },
  destroyed () {
    this.stopListenForKeys()
  },
  watch: {
    preview: {
      handler (val) {
        this.sliding = !val
      }
    }
  }
}

const getBackgroundSize = function (selector, par) {
  let style = selector.currentStyle || window.getComputedStyle(selector, false)
  let bi = style.backgroundImage.slice(4, -1).replace(/"/g, '')

  var actualImage = new Image()
  actualImage.src = bi

  actualImage.onload = function () {
    let ratio = this.height / this.width
    par.style.paddingBottom = (ratio * 100) + '%'
  }
}
</script>

<style lang='scss'>
@import "../../../scss/init";
$carouselTiming: .5s;

.media-wrapper {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}

.media-wrapper__with-blur {
  height: auto;
  margin-top: $header-height-floated;
  max-height: 100vh;
  position: relative;
  width: 100%;

  @include media-min-sm() {
    margin-top: $header-height;
  }
}

.media-wrapper__blurred {
  z-index: 0;
  height: 100%;
  left: 0;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.media-wrapper__list {
  height: 100%;
  left: 0;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  &.has-blur {
    margin: 0 auto;
    max-width: 1000px;
    position: relative;
  }
}

.media-wrapper__inner {
  @include animate(all, $carouselTiming);
  filter: grayscale($image_greyscale);
  height: 100%;
  left: 0;
  overflow: hidden;
  position: relative;
  top: 0;
  width: 100%;
  display: none;

  .has-blur & {
    max-width: $page-max-width;
  }

  &.next,
  &.prev,
  &.is-active {
    display: block;
  }

  &.is-active {
    left: 0;
  }

  &.next,
  &.prev {
    position: absolute;
    top: 0;
    width: 100%;
  }

  &.next {
    left: 100%;
  }
  &.prev {
    left: -100%;
  }

  &.next.left,
  &.prev.right {
    left: 0;
  }

  &.is-active.left {
    left: -100%;
  }

  &.is-active.right {
    left: 100%;
  }
}

.media-wrapper__image {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  filter: grayscale($image_greyscale);

  &.loading {
    background-size: 50px;
  }

  &.is-blurred {
    filter: blur(10px) grayscale(80%);
  }

  &.is-contain {
    background-size: contain !important;
    bottom: 0;
    position: absolute;
    z-index: 10;
    left: 0;
    width: 100%;

    @include media-min-sm() {
      top: $header-height;
    }
  }
}

.media-wrapper__title {
  bottom: 25px;
  color: color(seed-white, dark);
  padding: #{$size-xs}px;
  position: absolute;
  text-shadow: 0 1px 4px rgba(0,0,0,.75);
}

// Dots
.media-dots {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 0 20px;
  padding: 0;
  position: absolute;
  width: calc(100% - 40px);
  z-index: 3;

  @include media-max-sm {
    display: none;
  }

  .media-dot {
    @include animate(all, $carouselTiming);
    background-color: transparent;
    border: 1px solid color(seed-white);
    border-radius: 1px;
    // box-shadow: $depth-shadow-1;
    height: 8px;
    margin: 0 5px 5px;
    opacity: .5;
    width: 15px;

    &.is-active {
      background-color: color(seed-white);
    }
  }
}

.media-nav {
  @include animate(all, .5s);
  background: transparent;
  outline: none;
  pointer-events: all;
  position: absolute;
  top: 50%;
  z-index: 4;

  path {
    filter: drop-shadow( 0 0 1px rgba(0, 0, 0, .7));
  }
}

.media-nav__prev {
  left: 0;
  transform: translateX(-80px);

  @include media-max-sm {
    transform: translateX(0);
  }
}

.media-nav__next {
  right: 0;
  transform: translateX(80px);

  @include media-max-sm {
    transform: translateX(0);
  }
}

.media-wrapper:hover,
.media-wrapper__with-blur:hover {
  .media-nav__prev,
  .media-nav__next {
    transform: translateX(0);
  }

  .media-dot {
    opacity: 1;
  }
}

.media-nav-icon {
  height: 50px;
  width: 50px;
}
</style>
