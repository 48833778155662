import Vue from 'vue'

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('date', function (value) {
  if (!value) return ''

  let d = new Date(value * 1000)
  let date = d.toLocaleDateString() + ' - '
  date += d.getUTCHours() > 9 ? d.getUTCHours() : ('0' + d.getUTCHours()) + ':'
  date += d.getUTCMinutes() > 9 ? d.getUTCMinutes() : ('0' + d.getUTCMinutes())
  return date
})

Vue.filter('date-only', function (value) {
  if (!value) return ''

  let d = new Date(value * 1000)
  let date = d.toLocaleDateString()
  // date += d.getUTCHours() > 9 ? d.getUTCHours() : ('0' + d.getUTCHours()) + ':'
  // date += d.getUTCMinutes() > 9 ? d.getUTCMinutes() : ('0' + d.getUTCMinutes())
  return date
})

Vue.filter('unix-date', function (value) {
  if (!value) return ''

  let months_arr = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']

  // Convert timestamp to milliseconds
  let date = new Date(value * 1000)

  // Year
  let year = date.getFullYear()

  // Month
  let month = months_arr[date.getMonth()]

  // Day
  let day = date.getDate()

  // Hours
  let hours = date.getHours();

  // Minutes
  let minutes = "0" + date.getMinutes();

  // Seconds
  let seconds = "0" + date.getSeconds();

  // Display date time in MM-dd-yyyy h:m:s format
  let convdataTime = day + ' ' + month + ' ' + year + ' @ ' + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2)

  return convdataTime
})

Vue.filter('unix-date-only', function (value) {
  if (!value) return ''

  let months_arr = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']

  let date = new Date(value * 1000)
  let year = date.getFullYear()
  let month = months_arr[date.getMonth()]
  let day = date.getDate()

  let convdataTime = day + ' ' + month + ' ' + year

  return convdataTime
})

Vue.filter('pretty-date', function (value) {
  // let date = new Date(value)
  return value
})

Vue.filter('filesize', function (value) {
  if (value == 0) {
    return "0.00 B"
  }

  let e = Math.floor(Math.log(value) / Math.log(1024))
  return (value / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'B'
})

Vue.filter('truncate', function (text, length, clamp) {
  text = text || ''
  clamp = clamp || '...'
  length = length || 30

  if (text.length <= length) {
    return text
  }

  let tcText = text.slice(0, length - clamp.length)
  let last = tcText.length - 1

  while (last > 0 && tcText[last] !== ' ' && tcText[last] !== clamp[0]) last -= 1

  // Fix for case when text dont have any `space`
  last = last || length - clamp.length

  tcText = tcText.slice(0, last)

  return tcText + clamp
})
