<template>
  <header
    v-bind:class="[
      'header',
      scrolled ? 'is-floated' : '',
      solid ? 'is-solid' : ''
    ]">
    <div class="header-inner">
      <button class="btn header-menu-btn" @click.stop="menuDrawer = !menuDrawer">
        <icon class="header-menu-icon" iconName="menu" />
      </button>

      <router-link class="header-logo" to="/">
        <icon class="header-logo-icon" iconName="logo" viewbox="0 0 141.685 56.181" />
      </router-link>

      <a :href="'tel:' + telephone" class="btn header-telephone-btn" v-if="telephone">
        <icon class="header-menu-icon" iconName="phone" />
        <div class="label">{{telephone}}</div>
      </a>
    </div>
    <v-navigation-drawer
      absolute
      clipped
      left
      light
      :temporary="true"
      v-model="menuDrawer"
      class="flyout full-height"
      app>
        <!-- <Menu /> -->
        <v-layout light class="site-menu">
          <div class="menu-logo">
            <router-link to="/">
              <icon class="header-menu-logo-icon icon--black" iconName="logo" viewbox="0 0 141.685 56.181" />
            </router-link>
          </div>
          <nav class="inner-menu">
            <!-- {{siteNav}} -->
            <ul class="site-nav">
              <li
                v-for="(item, index) in mainMenu"
                :key="index"
                class="site-nav__item">
                <div class="site-nav__link" v-on:click="goTo(item.url)">
                  {{item.title}}
                </div>
                <ul class="site-nav site-nav--sub" v-if="item.sub.length > 0">
                  <li
                    v-for="(sub, ket) in item.sub"
                    :key="ket"
                    class="site-nav__item">
                    <div class="site-nav__link" v-on:click="goTo(sub.url)">
                      {{sub.title}}
                    </div>
                  </li>

                  <li class="site-nav-divier">
                    <v-divider color="black" />
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </v-layout>
      </v-navigation-drawer>
  </header>
</template>

<script>
import icon from '../components/icons/Icon'
export default {
  components: { icon },

  data () {
    return {
      scrolled: false,
      solid: false,
      hero: true,
      telephone: false,
      menuDrawer: false,
      mainMenu: false
    }
  },

  methods: {
    pageScroll () {
      this.scrolled = window.scrollY > 20
      this.solid = window.scrollY > 580
    },
    goTo (link) {
      console.log(link)
      this.menuDrawer = false
      this.$router.push({ path: link })
    }
  },

  created () {
    window.addEventListener('scroll', this.pageScroll)
    this.pageScroll()

    this.$store.watch(() => this.$store.getters.mainMenu, res => {
      if (res) {
        this.mainMenu = res
      }
    }, {
      immediate: true
    })

    this.$store.watch(() => this.$store.getters.genSettings, res => {
      if (res && res.telephone) {
        this.telephone = res.telephone
      }
    }, {
      immediate: true
    })
  },

  destroyed () {
    window.removeEventListener('scroll', this.pageScroll)
  }
}
</script>
