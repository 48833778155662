<template>
  <v-form
    ref="form"
    lazy-validation
    v-if="formData && !sent">
    <v-layout>
      <v-flex xs10 offset-xs1>
        <v-layout wrap>
          <v-flex xs12 pa-3>
            <h2 class="pb-3">{{panelTitle}}</h2>
          </v-flex>
          <v-flex xs4 px-3>
            <v-select
              :dark="colour !== 'white'"
              :light="colour === 'white'"
              v-model="formData.title"
              :items="titleOptions"
              outline
              label="Title"/>
          </v-flex>
          <v-flex xs8 px-3>
            <v-text-field
              :dark="colour !== 'white'"
              :light="colour === 'white'"
              v-model="formData.name"
              outline
              :error-messages="nameErrors"
              required
              @input="$v.formData.name.$touch()"
              @blur="$v.formData.name.$touch()"
              type="text"
              label="Name*" />
          </v-flex>
          <v-flex xs12 px-3>
            <v-text-field
              :dark="colour !== 'white'"
              :light="colour === 'white'"
              v-model="formData.email"
              outline
              :error-messages="emailErrors"
              required
              @input="$v.formData.email.$touch()"
              @blur="$v.formData.email.$touch()"
              type="email"
              label="Email*" />
          </v-flex>
          <v-flex xs12 px-3>
            <v-text-field
              :dark="colour !== 'white'"
              :light="colour === 'white'"
              v-model="formData.telephone"
              outline
              type="text"
              label="Telephone" />
          </v-flex>
          <v-flex xs12 px-3>
            <v-textarea
              :dark="colour !== 'white'"
              :light="colour === 'white'"
              v-model="formData.car_description"
              outline
              label="Description of the vehicle"
              auto-grow/>
          </v-flex>
          <v-flex xs12 pa-3 class="text-xs-center">
            <v-btn
              :loading="sending"
              :disabled="sending"
              outline
              large
              :dark="colour !== 'white'"
              :light="colour === 'white'"
              @click="send"
            >Send
              <template v-slot:loader>
                <span class="custom-loader">
                  <icon iconName="cached" :class="[ 'icon--white']" />
                </span>
              </template>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-form>

  <v-layout v-else>
    <v-flex xs12>
      <div class="title text-xs-center pt-3 pb-5">
      Thank you for your enquiry.
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import { postDataObj } from '../../api/api'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import icon from '../icons/Icon'

export default {
  name: 'concierge-sellers-form',

  components: {icon},

  mixins: [validationMixin],

  props: {
    colour: {
      type: String
    },
    params: {}
  },

  validations: {
    formData: {
      name: { required },
      email: { required, email }
    }
  },

  computed: {
    nameErrors () {
      const errors = []
      if (!this.$v.formData.name.$dirty) return errors
      !this.$v.formData.name.required && errors.push('Please enter your name.')
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.formData.email.$dirty) return errors
      !this.$v.formData.email.email && errors.push('Must be valid e-mail')
      !this.$v.formData.email.required && errors.push('Please enter your email.')
      return errors
    }
  },

  data() {
    return {
      formData: {
        title: '',
        name: '',
        email: '',
        telephone: '',
        car_description: '',
        car_options: 'Selling',
        type: 'concierge selling'
      },
      titleOptions: [
        'Mr', 'Mrs', 'Ms', 'Miss', 'Dr', 'Prof', 'Rev'
      ],
      sent: false,
      sending: false,
      panelTitle: 'Contact Us'
    }
  },

  methods: {
    send () {
      this.$v.$touch()

      if (!this.$v.$error) {
        this.sending = true
        let formData = JSON.parse(JSON.stringify(this.formData))

        postDataObj('sendEnquiry', formData).then(results => {
          if (results) {
            this.sending = false
            this.sent = true
          }
        })
      } else {
        this.toast = true
      }
    }
  },
  created () {
    if (this.params.panelTitle) {
      this.panelTitle = this.params.panelTitle
    }
  },
  destroyed () {
    this.sending = false
    this.sent = false
  }
}
</script>
